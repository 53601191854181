/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-button {
  text-transform: none;
  font-family: "Blinker-Regular";
}

// .native-input.sc-ion-input-md {
//   background: #f1eded;
// }




@media (min-width: 900px) {
  .example-modal {
    align-items: center;
    justify-content: center;

    &::part(content) {
      width: 90%;
      height: 90%;
    }
  }
}

@media (max-width: 900px) {
  .example-modal {
    align-items: center;
    justify-content: center;

    &::part(content) {
      width: 100%;
      height: 100%;
    }
  }
}




@font-face {
  font-family: "Blinker-Black";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/BLINKER/Blinker-Black.ttf");
}

@font-face {
  font-family: "Blinker-Bold";
  font-style: normal;
  font-weight: bold;
  src: url("/assets/BLINKER/Blinker-Bold.ttf");
}

@font-face {
  font-family: "Blinker-ExtraBold";
  font-style: italic;
  font-weight: bold;
  src: url("/assets/BLINKER/Blinker-ExtraBold.ttf");
}

@font-face {
  font-family: "Blinker-Light";
  font-style: italic;
  font-weight: normal;
  src: url("/assets/BLINKER/Blinker-Light.ttf");
}

@font-face {
  font-family: "Blinker-Regular";
  font-style: italic;
  font-weight: normal;
  src: url("/assets/BLINKER/Blinker-Regular.ttf");
}

@font-face {
  font-family: "Blinker-SemiBold";
  font-style: italic;
  font-weight: normal;
  src: url("/assets/BLINKER/Blinker-SemiBold.ttf");
}

@font-face {
  font-family: "Blinker-Thin";
  font-style: italic;
  font-weight: normal;
  src: url("/assets/BLINKER/Blinker-Thin.ttf");
}

* {
  font-family: "Blinker-Regular";
}

.header-md::after {
  background-image: none;
}

.sc-ion-input-md-h {
  font-family: Blinker-Regular;
}

.cursor-pointer {
  cursor: pointer;
}